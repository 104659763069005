import { ApiError } from '~/utils/api';

export class ApiSupplierError extends ApiError {}

export interface SupplierAddress {
  address: string;
  city: string;
  zipcode: string;
}

export interface SupplierAPIDTO {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _id: {
    $oid: string;
  };
  supplierId: string;
  status: string;
  name: string;
  companyName: string;
  siret: string;
  vatNumber: string;
  legalForm: string;
  rcs: string;
  nafCode: string;
  capital: string;
  bic: string;
  iban: string;
  legalRepresentative: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    jobTitle: string;
  };
  headOfficeAddress: SupplierAddress;
  returnAddress: SupplierAddress;
  updatedAt: {
    $date: string;
  };
  createdAt: {
    $date: string;
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __v: number;
}

export interface Supplier {
  id: string;
  name: string;
}

export function getSupplierBaseUrl(): string {
  return `${useRuntimeConfig().public.siteUrl}/api/supplier`;
}

export function mapSupplierApiToSupplier({ supplierId, name }: SupplierAPIDTO): Supplier {
  return {
    id: supplierId,
    name,
  };
}

export async function getSupplierFromToken(): Promise<Supplier> {
  const {
    $api: { authenticatedFetch },
  } = useNuxtApp();

  try {
    const data = await authenticatedFetch<SupplierAPIDTO>('/supplier', {
      baseURL: getSupplierBaseUrl(),
    });

    return mapSupplierApiToSupplier(data);
  } catch (error) {
    throw new ApiSupplierError('Failed to get supplier', { cause: error });
  }
}
