import { getSupplierFromToken } from '~/utils/api/supplier';

export default function useSupplierProductsOnly() {
  const shouldDisplaySupplierProductsOnly = useState('shouldDisplaySupplierProductsOnly', () => true);
  const { isSupplierUser } = useAuth();

  const filters = computedAsync(async () => {
    if (!shouldDisplaySupplierProductsOnly.value || !isSupplierUser.value) {
      return '';
    }

    const supplier = await getSupplierFromToken();

    return `supplier.id:${supplier.id}`;
  }, '');

  return {
    shouldDisplaySupplierProductsOnly,
    filters,
  };
}
